<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="mb-7 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="tableProperties"
      ></datatable-detail>
    </div>
    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="regions"
        editType="region"
        :handleAddFunction="() => handleAddFunction('region')"
        :handleGetFunction="getCountries"
      />
      <NestedDataTable
        tableName="countries"
        editType="country"
        :handleAddFunction="() => handleAddFunction('country')"
      />
    </div>
    <AddRegion ref="addRegionDialog" />
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import regionNestedDataMixin from "@/own/mixins/regionNestedDataMixin";
import AddRegion from "@/own/components/shipping/regions/Add/AddRegion";
import NestedDataTable from "@/own/components/shipping/regions/NestedDataTable";

export default {
  name: "NestedData",
  components: {
    NestedDataTable,
    AddRegion,
    DatatableDetail,
  },
  mixins: [regionNestedDataMixin],
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 327px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 285px) !important;
  }
}

@media (max-width: 768px) {
  .custom-container-height {
    height: calc(100vh - 275px) !important;
  }
}
</style>
