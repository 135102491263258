<template>
  <div>
    <v-menu left rounded>
      <template v-slot:activator="{ on, attrs }">
        <button class="btn btn-sm btn-icon" v-bind="attrs" v-on="on">
          <span class="svg-icon">
            <v-icon size="19"> mdi-dots-vertical </v-icon>
          </span>
        </button>
      </template>

      <v-list class="py-0">
        <v-list-item
          @click.prevent="() => editItem()"
          v-if="editType == 'region'"
          class="text-center bg-hover-light-info"
        >
          <v-list-item-title class="poppins iq-color">Edit</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click.prevent="() => removeItem(itemId)"
          class="text-center bg-hover-light-info"
        >
          <v-list-item-title class="poppins iq-color">Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <EditRegion ref="editRegion" :id="itemId" :editType="editType" />
  </div>
</template>

<script>
import swalDeleteQuery from "@/core/services/AlertServices/SwalDeleteQuery";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import EditRegion from "@/own/components/shipping/regions/Edit/EditRegion";
import {
  GET_REGIONS,
  GET_COUNTRIES,
} from "@/core/services/store/shipping_regions.module";

export default {
  name: "ActionsMenu",
  components: { EditRegion },
  props: ["itemId", "editType"],
  data: () => ({
    editCountry: false,
    editState: false,
    editCity: false,
    editArea: false,
  }),
  methods: {
    editItem() {
      if (this.editType === "region") this.$refs.editRegion.toggleModal();
      else this.$refs.editArea.toggleModal();
    },
    removeItem(id) {
      swalDeleteQuery().then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(SET_PAGE_LOADING, true);
          ApiService.post("/shipping/regions/destroy", {
            id,
            type: this.editType,
          }).then(() => {
            Swal.fire("Deleted!", "Record has been deleted.", "success").then(
              (res) => {
                if (res.isConfirmed) {
                  if (this.editType === "region")
                    this.$store.dispatch(GET_REGIONS).finally(() => {
                      this.$store.commit(SET_PAGE_LOADING, false);
                    });
                  else {
                    let payload = {
                      region_id:
                        this.$store.getters.getSHIPPINGREGIONSSelectedRegionId,
                      type: "country",
                    };
                    this.$store.dispatch(GET_COUNTRIES, payload).finally(() => {
                      this.$store.commit(SET_PAGE_LOADING, false);
                    });
                  }
                }
              }
            );
          });
        }
      });
    },
  },
};
</script>
