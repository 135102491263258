import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import { GET_REGIONS } from "@/core/services/store/shipping_regions.module";

export default {
  methods: {
    loadDataFromServer() {
      let sendData = {
        id: this.id,
        type: this.editType,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/shipping/regions/edit", sendData)
        .then(({ data }) => {
          this.formData = data.region;
          this.countries = data.countries;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    sentToApi(sendData) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/shipping/regions/update", sendData)
        .then(() => {
          swalEdited();
          this.toggleModal();
          this.$store.dispatch(GET_REGIONS).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
